<template>
  <div class="modal-body">
    <form>
      <div class="form-group row">
        <div class="col-md-2">
          <label for="">Banque:</label>
        </div>
        <template v-if="compte == null">
          <div class="col-md-5">{{ selectedTransaction.compte_bancaire.numero }} ({{ selectedTransaction.compte_bancaire.banque.denomination }})</div>
        </template>
        <template v-else>
          <div class="col-md-5">{{ compte.compte_bancaire.numero }} ({{ compte.compte_bancaire.banque.denomination }})</div>
        </template>
      </div>
      <div class="form-group row">
        <div class="col-md-2">
          <label for="">Date:</label>
        </div>
        <div class="col-md-2">
          {{ selectedTransaction.operation }}
        </div>
        <div class="col-md-3">
          <label for="">Libellé opération:</label>
        </div>
        <div class="col-md-5">
          {{ selectedTransaction.details_releve }}
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-2">
          <label for="">Débit:</label>
        </div>
        <div v-if="selectedTransaction.debit"
             class="col-md-4">{{ Math.round(Number(selectedTransaction.debit)).toLocaleString() }} FCFA</div>
        <div v-else
             class="col-md-4">0 FCFA</div>
        <div class="col-md-2">
          <label for="">Crédit:</label>
        </div>
        <div v-if="selectedTransaction.credit"
             class="col-md-4">{{ Math.round(Number(selectedTransaction.credit)).toLocaleString() }} FCFA</div>
        <div v-else
             class="col-md-4">0 FCFA</div>
      </div>
      <div class="form-group row">
        <div class="col-md-2">
          <label for="">Référence:</label>
        </div>
        <div class="col-md-4">
          {{ selectedTransaction.reference }}
        </div>
        <div class="col-md-2">
          <label for="">Commentaires:</label>
        </div>
        <div class="col-md-4">
          {{ selectedTransaction.commentaire }}
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-2">
          <label for="">Type</label>
        </div>
        <div class="col-md-4">
          {{ selectedTransaction.type }}
        </div>
        <div class="col-md-2">
          <label for="">Sous-type</label>
        </div>
        <div class="col-md-4">
          {{ selectedTransaction.sous_type }}
        </div>
      </div>
      <fieldset class="sous-item pt-3 pl-1">
        <div v-if="selectedTransaction.sous_type == 'RECETTES_PEAGE'"
             class="form-group row">
          <div class="col-md-4">
            <label for="">Zone de gare:</label>
            <p>{{selectedTransaction.zone_gare}}</p>
          </div>
          <div class="col-md-4">
            <label for="">Gare de péage:</label>
            <p>{{selectedTransaction.gare_peage}}</p>
          </div>
          <div class="col-md-4">
            <label for="">Classe de véhicule:</label>
            <p>{{selectedTransaction.classe_vehicule}}</p>
          </div>
        </div>
        <div v-if="selectedTransaction.sous_type == 'REDEVANCES_PESAGE'"
             class="form-group row">
          <div class="col-md-3">
            <label for="">Gare de pesage:</label>
          </div>
          <div class="col-md-5">
            {{selectedTransaction.gare_pesage}}
          </div>
        </div>
        <div v-if="selectedTransaction.sous_type == 'TSU_PATENTES_VIGNETTES'"
             class="form-group row">
          <div class="col-md-3">
            <label for="">TSU,Patentes,Vignettes:</label>
          </div>
          <div class="col">
            {{selectedTransaction.type_recettes_affectes}}
          </div>
        </div>
        <div v-if="selectedTransaction.sous_type == 'REVENUS_FINANCIER_AUTRES_PRODUITS'"
             class="form-group row">
          <div class="col-md-3">
            <label for="">Type de revenus:</label>
          </div>
          <div class="col-md-5">
            {{selectedTransaction.type_revenus}}
          </div>
        </div>
        <div v-if="selectedTransaction.sous_type == 'DEPENSES_PEAGE'"
             class="form-group row">
          <div class="col-md-2">
            <label for="">Type de dépenses de péage:</label>
          </div>
          <div class="col-md-4">
            {{selectedTransaction.type_depenses_peage}}
          </div>
          <div class="col-md-2">
            <label for="">Détails de dépenses de péage:</label>
          </div>
          <div class="col-md-4">
            {{selectedTransaction.details_depenses_peage}}
          </div>
        </div>
        <div v-if="selectedTransaction.sous_type == 'DEPENSES_PESAGE'"
             class="form-group row">
          <div class="col-md-2">
            <label for="">Type de dépenses de pesage:</label>
          </div>
          <div class="col-md-4">
            {{selectedTransaction.type_depenses_pesage}}
          </div>
          <div class="col-md-2">
            <label for="">Détails de dépenses de pesage:</label>
          </div>
          <div class="col-md-4">
            {{selectedTransaction.details_depenses_pesage}}
          </div>
        </div>
        <div v-if="selectedTransaction.sous_type == 'AUTRES_DEPENSES_ASSIMILEES'"
             class="form-group row">
          <div class="col-md-2">
            <label for="">Type de dépenses autres dépenses assimilées:</label>
          </div>
          <div class="col-md-4">
            {{selectedTransaction.type_depenses_ada}}
          </div>
          <div class="col-md-2">
            <label for="">Détails de dépenses autres dépenses assimilées:</label>
          </div>
          <div class="col-md-4">
            {{selectedTransaction.details_depenses_ada}}
          </div>
        </div>
        <div v-if="selectedTransaction.sous_type == 'EMPRUNTS_FACILITES_TRESORERIE'"
             class="form-group row">
          <div class="col-md-2">
            <label for="">Type de dépenses emprunts de facilité trésorie:</label>
          </div>
          <div class="col-md-4">
            {{selectedTransaction.type_depenses_eft}}
          </div>
          <div class="col-md-2">
            <label for="">Détails de dépenses emprunts de facilité trésorie:</label>
          </div>
          <div class="col-md-4">
            {{selectedTransaction.details_depenses_eft}}
          </div>
        </div>
        <div v-if="selectedTransaction.sous_type == 'CHARGES_FONCTIONNEMENT_IMMO'"
             class="form-group row">
          <div class="col-md-2">
            <label for="">Type de dépenses charges de fonctionnement immobilière:</label>
          </div>
          <div class="col-md-4">
            {{selectedTransaction.type_depense}}
          </div>
          <div class="col-md-2">
            <label for="">Détails de dépenses charges de fonctionnement immobilière:</label>
          </div>
          <div class="col-md-4">
            {{selectedTransaction.details_depenses}}
          </div>
        </div>
        <div v-if="selectedTransaction.sous_type == 'PROGRAMME_ENTRETIEN_ROUTIER'"
             class="form-group row">
          <div class="col-md-3">
            <label for="">Décaissements:</label>
          </div>
          <div class="col-md-5">
            {{selectedTransaction.decaissement.decompte}}
          </div>
        </div>
        <div v-if="selectedTransaction.sous_type == 'LEVEE_FONDS'"
             class="form-group row">
          <div class="col-md-2">
            <label for="">Facilité:</label>
          </div>
          <div class="col-md-4">
            {{selectedTransaction.levee_fond.facilite}}
          </div>
          <div class="col-md-2">
            <label for="">Tranche:</label>
          </div>
          <div class="col-md-4">
            {{selectedTransaction.levee_fond.tranche}}
          </div>
        </div>
      </fieldset>
      
    </form>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
export default {
  name: "Categorized",
  data: () => ({
    selected: []
  }),
  created() {
    // this.loadTransactionsId(this.selectedTransaction)
  },
  props: ["selectedTransaction", "compte"],
  computed: {
    ...mapGetters(["transactionsId"])
  },
  methods: {
    ...mapActions(["loadTransactionsId"]),
    ...mapMutations(["setErrors", "setTransactionsId"])
  },
  watch: {
    selectedTransaction() {
      if (this.selectedTransaction) {
        // this.loadTransactionsId(this.selectedTransaction)
        // console.log(this.selectedTransaction)
      }
    }
  }
}
</script>

<template>
  <div class="main_container">
    <div class="row mb-3">
      <div class="text-left col-12">
        <router-link :to="'/operation/operationsBancaires'">
          Opérations bancaires>
        </router-link>
        <span>Liste des transactions</span>
      </div>
    </div>
    <h2 class="page_title text-center">LISTE DES TRANSACTIONS</h2>
    <div class="row my-3">
      <div v-if="(user[1] == 'FER' && (user[0] == 'DAF'|| user[0]=='DCG'))||(user[1] == 'FER' && user[0] == 'ADMIN')"
           class="div_left">
        <router-link :to="{ path: '/operation/ajoutDeTransaction' }"
                     class="btn btn-blue">
          Ajouter une transaction
        </router-link>
      </div>
      <div class="div_left mx-auto">
        <button class="btn btn-blue pl-5 pr-5 op-btn"
                data-toggle="modal"
                ref="modal_button"
                data-target="#filtreModal">Filtrer</button>
      </div>
      <div class="div_left">
        <button class="btn btn-blue"
                @click="downloadExport(comptabiliteTransactions.url)"
                download>
          Exporter
        </button>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-12">
        <button class="btn btn-primary"
                @click="loadGroupedTransactions(true)"
                v-bind:class="{ 'lt-btn-active': groupedTransactions, 'lt-btn-inactive': !groupedTransactions }">
          {{ longueur }} transactions <br />
          non-catégorisées
        </button>
        <button class="btn btn-primary ml-5"
                @click="loadGroupedTransactions(false)"
                v-bind:class="{ 'lt-btn-active': !groupedTransactions, 'lt-btn-inactive': groupedTransactions }">
          Voir toutes les <br />
          transactions <br />
          catégorisées
        </button>
      </div>
    </div> -->
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <div class="pt-5">
      <table class="table tablestyle_3 table-striped">
        <thead>
          <tr>
            <th scope="col"
                class="text-left dropdown">
              <button class="dropdown-toggle th-blue"
                      type="button"
                      id="triggerId"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                Banque
              </button>
              <!-- Dropdown content-->
              <div class="dropdown-menu"
                   aria-labelledby="triggerId">
                <div class="form-check ml-3">
                  <input class="form-check-input"
                         type="radio"
                         name="flexRadioDefault"
                         id="flexRadioDefault1"
                         value="asc"
                         v-model="transData.order" />
                  <label class="form-check-label"
                         for="flexRadioDefault1">
                    A - Z
                  </label>
                </div>
                <div class="form-check ml-3">
                  <input class="form-check-input"
                         type="radio"
                         name="flexRadioDefault"
                         id="flexRadioDefault2"
                         value="desc"
                         v-model="transData.order" />
                  <label class="form-check-label"
                         for="flexRadioDefault2">
                    Z - A
                  </label>
                </div>
                <h6 class="dropdown-header">Recherche:</h6>
                <div class="form-group px-3">
                  <input class="form-control"
                         type="text"
                         v-model="transData.libelle" />
                </div>
                <a name=""
                   id=""
                   class=" px-3 ml-4 btn btn-primary"
                   href="#"
                   @click=";(transData.tri = 'banques.denomination'), filtrer()"
                   role="button">Envoyer</a>
              </div>
            </th>
            <th scope="col"
                class="text-left dropdown">
              <button class="dropdown-toggle th-blue"
                      type="button"
                      id="triggerId"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                Date
              </button>
              <!-- Dropdown content-->
              <div class="dropdown-menu"
                   aria-labelledby="triggerId">
                <div class="form-check ml-3">
                  <input class="form-check-input"
                         type="radio"
                         name="flexRadioDefault"
                         id="flexRadioDefault1"
                         value="asc"
                         v-model="transData.order" />
                  <label class="form-check-label"
                         for="flexRadioDefault1">
                    Croissant
                  </label>
                </div>
                <div class="form-check ml-3">
                  <input class="form-check-input"
                         type="radio"
                         name="flexRadioDefault"
                         id="flexRadioDefault2"
                         value="desc"
                         v-model="transData.order" />
                  <label class="form-check-label"
                         for="flexRadioDefault2">
                    Décroissant
                  </label>
                </div>
                <h6 class="dropdown-header">Recherche:</h6>
                <div class="form-group px-3">
                  <input class="form-control"
                         type="text"
                         v-model="transData.libelle" />
                </div>
                <a name=""
                   id=""
                   class=" px-3 ml-4 btn btn-primary"
                   href="#"
                   @click=";(transData.tri = 'transactions.operation'), filtrer()"
                   role="button">Envoyer</a>
              </div>
            </th>
            <th scope="col"
                class="text-left dropdown">
              <button class="dropdown-toggle th-blue"
                      type="button"
                      id="triggerId"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                Libellé Opération
              </button>
              <!-- Dropdown content-->
              <div class="dropdown-menu"
                   aria-labelledby="triggerId">
                <div class="form-check ml-3">
                  <input class="form-check-input"
                         type="radio"
                         name="flexRadioDefault"
                         id="flexRadioDefault1"
                         value="asc"
                         v-model="transData.order" />
                  <label class="form-check-label"
                         for="flexRadioDefault1">
                    A - Z
                  </label>
                </div>
                <div class="form-check ml-3">
                  <input class="form-check-input"
                         type="radio"
                         name="flexRadioDefault"
                         id="flexRadioDefault2"
                         value="desc"
                         v-model="transData.order" />
                  <label class="form-check-label"
                         for="flexRadioDefault2">
                    Z - A
                  </label>
                </div>
                <h6 class="dropdown-header">Recherche:</h6>
                <div class="form-group px-3">
                  <input class="form-control"
                         type="text"
                         v-model="transData.libelle" />
                </div>
                <a name=""
                   id=""
                   class=" px-3 ml-4 btn btn-primary"
                   href="#"
                   @click=";(transData.tri = 'transactions.libelle'), filtrer()"
                   role="button">Envoyer</a>
              </div>
            </th>
            <th scope="col"
                class="text-left dropdown">
              <button class="dropdown-toggle th-blue"
                      type="button"
                      id="triggerId"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                Débit (FCFA)
              </button>
              <!-- Dropdown content-->
              <div class="dropdown-menu"
                   aria-labelledby="triggerId">
                <div class="form-check ml-3">
                  <input class="form-check-input"
                         type="radio"
                         name="flexRadioDefault"
                         id="flexRadioDefault1"
                         value="asc"
                         v-model="transData.order" />
                  <label class="form-check-label"
                         for="flexRadioDefault1">
                    Croissant
                  </label>
                </div>
                <div class="form-check ml-3">
                  <input class="form-check-input"
                         type="radio"
                         name="flexRadioDefault"
                         id="flexRadioDefault2"
                         value="desc"
                         v-model="transData.order" />
                  <label class="form-check-label"
                         for="flexRadioDefault2">
                    Décroissant
                  </label>
                </div>
                <h6 class="dropdown-header">Recherche:</h6>
                <div class="form-group px-3">
                  <input class="form-control"
                         type="text"
                         v-model="transData.libelle" />
                </div>
                <a name=""
                   id=""
                   class=" px-3 ml-4 btn btn-primary"
                   href="#"
                   @click=";(transData.tri = 'transactions.debit'), filtrer()"
                   role="button">Envoyer</a>
              </div>
            </th>
            <th scope="col"
                class="text-left dropdown">
              <button class="dropdown-toggle th-blue"
                      type="button"
                      id="triggerId"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                Crédit (FCFA)
              </button>
              <!-- Dropdown content-->
              <div class="dropdown-menu"
                   aria-labelledby="triggerId">
                <div class="form-check ml-3">
                  <input class="form-check-input"
                         type="radio"
                         name="flexRadioDefault"
                         id="flexRadioDefault1"
                         value="asc"
                         v-model="transData.order" />
                  <label class="form-check-label"
                         for="flexRadioDefault1">
                    Croissant
                  </label>
                </div>
                <div class="form-check ml-3">
                  <input class="form-check-input"
                         type="radio"
                         name="flexRadioDefault"
                         id="flexRadioDefault2"
                         value="desc"
                         v-model="transData.order" />
                  <label class="form-check-label"
                         for="flexRadioDefault2">
                    Décroissant
                  </label>
                </div>
                <h6 class="dropdown-header">Recherche:</h6>
                <div class="form-group px-3">
                  <input class="form-control"
                         type="text"
                         v-model="transData.libelle" />
                </div>
                <a name=""
                   id=""
                   class=" px-3 ml-4 btn btn-primary"
                   href="#"
                   @click=";(transData.tri = 'transactions.credit'), filtrer()"
                   role="button">Envoyer</a>
              </div>
            </th>
            <th scope="col"
                class="text-left dropdown">
              <button class="dropdown-toggle th-blue"
                      type="button"
                      id="triggerId"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                Compte
              </button>
            </th>
          </tr>
        </thead>
        <tbody v-if="!loaded">
          <tr>
            <td class="text-center"
                colspan="6">Chargement ...</td>
          </tr>
        </tbody>
        <!-- liste des transactions non catégorisées  v-else-if="groupedTransactions == true"-->
        <tbody v-else-if="loaded && longueur != 0">
          <tr v-for="transaction in comptabiliteTransactions.donnees"
              :key="transaction.id">
            <td>
              <label>
                <input type="checkbox"
                       @change="checkCheckbox($event, transaction)" 
                       v-if="(user[1] == 'FER' && (user[0] == 'DAF'|| user[0]=='DCG'))||(user[1] == 'FER' && user[0] == 'ADMIN')"/>
                <span class="ml-2">
                  {{ transaction.compte_bancaire.banque.denomination }}
                </span>
              </label>
            </td>
            <td>
              {{ transaction.operation }}
            </td>
            <td>
              {{ transaction.libelle }}
            </td>
            <td>
              {{ Math.round(transaction.debit).toLocaleString() }}
            </td>
            <td>
              {{ Math.round(transaction.credit).toLocaleString() }}
            </td>
            <td v-if="transaction.plan_compte.compte != null">
              {{ transaction.plan_compte.compte }}
            </td>
            <td v-else
                class="txt-blue">
              999999999
            </td>
          </tr>
        </tbody>
        <!-- Liste des transactions catégorisées -->
        <tbody v-else>
          <tr v-if="longCat == 0">
            <td class="text-center"
                colspan="6">
              Aucune transaction enregistré
            </td>
          </tr>
          <!-- <tr v-for="transaction in transactionsCat.donnees"
              :key="transaction.id"
              @click="launchModal(transaction)">
            <td>
              <label>
               <input type="checkbox"
                       @change="checkCheckbox($event, transaction)" />
                <span class="ml-2"> 
                {{ transaction.compte_bancaire.banque.denomination }}
                </span> 
              </label>
            </td>
            <td>
              {{ transaction.operation }}
            </td>
            <td>
              {{ transaction.details_releve }}
            </td>
            <td>
              {{ Math.round(transaction.debit).toLocaleString() }}
            </td>
            <td>
              {{ Math.round(transaction.credit).toLocaleString() }}
            </td>
          </tr> -->
        </tbody>
      </table>
      <div v-if="groupedTransactions == true"
           class="row pagination">
        <div v-if="comptabiliteTransactions.pagination"
             class="col-md-1 mx-auto text-center">
          <span v-if="comptabiliteTransactions.pagination.precedent"
                class="badge badge-pill badge-info"
                @click="pIndex--, paging(comptabiliteTransactions.pagination.precedent)"> &#60; </span>
          {{ pIndex }}
          <span v-if="comptabiliteTransactions.pagination.suivant"
                class="badge badge-pill badge-info"
                @click="pIndex++, paging(comptabiliteTransactions.pagination.suivant)"> &#62; </span>
        </div>
      </div>
      <div v-else
           class="row pagination">
        <div v-if="transactionsCat.pagination"
             class="col-md-1 mx-auto text-center">
          <span v-if="transactionsCat.pagination.precedent"
                class="badge badge-pill badge-info"
                @click="index--, pagingCat(transactionsCat.pagination.precedent)"> &#60; </span>
          {{ index }}
          <span v-if="transactionsCat.pagination.suivant"
                class="badge badge-pill badge-info"
                @click="index++, pagingCat(transactionsCat.pagination.suivant)"> &#62; </span>
        </div>
      </div>
    </div>

    <!-- Button trigger modal -->
    <button v-show="showing"
            type="button"
            class="btn btn-primary"
            ref="modal_button"
            data-toggle="modal"
            data-target="#exampleModal">
      Launch demo modal
    </button>
    <!----------------------------------------- Modal de catégorisation ------------------------------------------->
    <!-- Modal -->
    <div class="modal fade "
         id="exampleModal"
         tabindex="-1"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"
                id="exampleModalLabel">Catégorisation de la transaction</h5>
            <button type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!-------------------------- Corps du Modal ------------------------------->
          <div v-if="!cat"
               class="modal-body">
            <form>
              <div class="form-group row">
                <div class="col-md-2">
                  <label for="">Banque:</label>
                </div>
                <div class="col-md-5"
                     v-if="selectedTransaction">{{ selectedTransaction.compte_bancaire.numero }} ({{ selectedTransaction.compte_bancaire.banque.denomination }})</div>
              </div>
              <div class="form-group row">
                <div class="col-md-2">
                  <label for="">Date:</label>
                </div>
                <div class="col-md-2"
                     v-if="selectedTransaction">
                  {{ selectedTransaction.operation }}
                </div>
                <div class="col-md-3">
                  <label for="">Libellé opération:</label>
                </div>
                <div class="col-md-5"
                     v-if="selectedTransaction">
                  {{ selectedTransaction.details_releve }}
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-2">
                  <label for="">Débit:</label>
                </div>
                <div class="col-md-4"
                     v-if="selectedTransaction">{{ Math.round(selectedTransaction.debit).toLocaleString() }} FCFA</div>
                <div class="col-md-2">
                  <label for="">Crédit:</label>
                </div>
                <div class="col-md-4"
                     v-if="selectedTransaction">{{ Math.round(selectedTransaction.credit).toLocaleString() }} FCFA</div>
              </div>
              <div class="form-group row">
                <div class="col-md-6">
                  <label for="">Référence</label>
                  <input class="form-control"
                         type="text"
                         name=""
                         id=""
                         v-model="form.reference" />
                  <span v-if="form.errors().has('reference')"
                        v-text="form.errors().get('reference')"
                        class="errorMsg"> </span>
                </div>
                <div class="col-md-6">
                  <label for="">Commentaires:</label>
                  <input class="form-control"
                         type="text"
                         name=""
                         id=""
                         v-model="form.commentaires" />
                  <span v-if="form.errors().has('commentaires')"
                        v-text="form.errors().get('commentaires')"
                        class="errorMsg"> </span>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-6">
                  <label for="">Type</label>
                  <select class="form-control"
                          name=""
                          id=""
                          v-model="form.type"
                          @change="getSousType(form.type)">
                    <option v-for="(type, tkey) in typeTransactions"
                            :key="tkey"
                            :value="type.type">{{ type.type }}</option>
                  </select>
                  <span v-if="form.errors().has('type')"
                        v-text="form.errors().get('type')"
                        class="errorMsg"> </span>
                </div>
                <div class="col-md-6">
                  <label for="">Sous-type</label>
                  <select v-if="form.type == 'LEVEE_FONDS'"
                          class="form-control"
                          name=""
                          id=""
                          v-model="form.sous_type"
                          @change="getChamps(form.sous_type)">
                    <option :value="sousTypeList.sous_type">{{ sousTypeList.sous_type }}</option>
                  </select>
                  <select v-else
                          class="form-control"
                          name=""
                          id=""
                          v-model="form.sous_type"
                          @change="getChamps(form.sous_type)">
                    <option v-for="(sousType, skey) in sousTypeList"
                            :key="skey"
                            :value="sousType.sous_type">{{ sousType.sous_type }}</option>
                  </select>
                  <span v-if="form.errors().has('sous_type')"
                        v-text="form.errors().get('sous_type')"
                        class="errorMsg"> </span>
                </div>
              </div>
              <!-- Debut partie des champs conditionnels -->
              <!-- if champList is an array -->
              <fieldset v-if="champList[0]"
                        class="sous-item pt-3 pl-1">
                <!-- if champList is not from Levee_fonds -->
                <template v-if="form.sous_type != 'LEVEE_FONDS'">
                  <div class="form-group row">
                    <div v-for="(champ, chkey) in champList"
                         :key="chkey"
                         class="col-md-4">
                      <label for="">{{ champ.champ }}</label>

                      <v-select v-if="champ.valeurs.length != 0"
                                :options="champ.valeurs"
                                
                                label="champ"
                                v-model="reform[champ.champ]">

                      </v-select>
                      <input v-else
                             class="form-control"
                             type="text"
                             name=""
                             id=""
                             v-model="reform[champ.champ]" />
                    </div>
                  </div>
                </template>
                <!-- if champList is from levee_fonds -->
                <template v-else>
                  <div v-if="leveefond.donnees.length == 0">
                    <h5 class="text-center">Aucune levée de fonds enregistrées.</h5>
                  </div>
                  <div v-else>
                    <div class="form-group row">
                      <div class="col-md-4">
                        <label for="">Facilité :</label>
                      </div>
                      <div class="col-md-8">
                        <select class="form-control"
                                name=""
                                id=""
                                v-model="reform['levee_fond']">
                          <option v-for="(valeur, vakey) in leveefond.donnees"
                                  :key="vakey.id"
                                  :value="valeur.id">{{ valeur.facilite }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-md-4">
                        <label for="">Tranche :</label>
                      </div>
                      <div class="col-md-8">
                        <div class="form-group">
                          <input type="number"
                                 class="form-control"
                                 name=""
                                 id=""
                                 v-model="reform['tranche_levee_fond']" />
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </fieldset>
              <!-- fin if champList is an array -->
              <!-- if champList is an object -->
              <fieldset v-if="champList['champ']"
                        class="sous-item pt-3 pl-1">
                <div class="form-group row">
                  <div class="col-md-4">
                    <label for="">{{ champList.champ }}</label>
                  </div>
                  <div v-if="champList.valeurs[0]"
                       class="col-md-8">
                    <select class="form-control"
                            name=""
                            id=""
                            v-model="reform[champList.champ]">
                      <option v-for="(valeur, vakey) in champList.valeurs"
                              :key="vakey"
                              :value="valeur">{{ valeur }}</option>
                    </select>
                  </div>
                  <div v-else
                       class="col-md-8">
                    <input class="form-control"
                           type="text"
                           name=""
                           id=""
                           v-model="reform[champList.champ]" />
                  </div>
                </div>
              </fieldset>
              <!-- fin if champList is an object -->
              <!-- Fin partie des champs conditionnels -->
            </form>
          </div>

          <!------------------------------------- fin modal body --------------------------------------------->
          <Categorized v-if="cat"
                       :selectedTransaction="selected"
                       :compte="null" />
          <div v-if="!cat"
               class="modal-footer">
            <button type="button"
                    class="btn btn-secondary"
                    ref="close_modal"
                    data-dismiss="modal">Annuler</button>
            <button type="button"
                    class="btn btn-primary"
                    @click="submit">Valider</button>
          </div>
        </div>
      </div>
    </div>
    <!--endModal--->
    <!-- Modal filtre-->
    <div class="modal fade col-md-12"
         id="filtreModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="filtreModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-lg"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"
                id="filtreModalLabel">Paramètres de filtre</h5>
            <button type="button"
                    class="close"
                    ref="btn-close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ListFilter :champs="chmp"/>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal">Annuler</button>
            <button type="button"
                    class="btn btn-primary"
                    @click="filtrer()">filtrer</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import Notif from "@/components/shared/Toast"
import Categorized from "./categorisation/Categorized"
import VSelect from "vue-select"
import 'vue-select/dist/vue-select.css'
import form from "vuejs-form"
import ListFilter from "@/components/shared/Filter"

export default {
  name: "ListeDesTransactions",
  components:{
    ListFilter,
    VSelect,
    Notif,
    Categorized
  },
  data: () => ({
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    reform: "",
    filtre:[],
    chmp:[
      {
        valeur:"sigle",
        type:"caractere",
        table:"banques",
        libelle:"Banque",
      },
      {
        valeur:"date",
        type:"date",
        table:"comptabilite_globales",
        libelle:"Date",
      },
      {
        valeur:"libelle",
        type:"caractere",
        table:"comptabilite_globales",
        libelle:"Libellé opération",
      },
      {
        valeur:"debit",
        type:"numeric",
        table:"comptabilite_globales",
        libelle:"Débit",
      },
      {
        valeur:"credit",
        type:"numeric",
        table:"comptabilite_globales",
        libelle:"Crédit",
      },
      {
        valeur:"compte",
        type:"caractere",
        table:"plan_comptes",
        libelle:"Compte",
      },
    ],
    form: form({
      type: "",
      sous_type: "",
      reference: "",
      commentaires: "",
      champ: "",
      valeur: ""
    })
      .rules({
        type: "required",
        sous_type: "required",
        reference: "required",
        commentaires: "required"
      })
      .messages({
        type: "Ce champs est requis!",
        sous_type: "Ce champs est requis!",
        reference: "Ce champs est requis!",
        commentaires: "Ce champs est requis!"
      }),
    transData: {
      libelle: null,
      order: "asc",
      tri: null,
      en_attente: 1
    },
    stat: true,
    longueur: 0,
    longCat: 0,
    long: 0,
    sousTypeList: [],
    champList: [],
    valeurList: [],
    pIndex: 1,
    index: 1,
    id: "",
    user:"",
    selected: "",
    cat: false,
    showing: false,
    groupedTransactions: true,
    selectedTransaction: null,
    loaded: false
  }),
  watch: {
    // transactions() {
    //   if (this.transactions.donnees) {
    //     this.loaded = true
    //     this.longueur = this.transactions.donnees.length
    //   }
    // },
    comptabiliteTransactions(){
      if (this.comptabiliteTransactions.donnees) {
        this.loaded=true
        this.longueur =this.comptabiliteTransactions.donnees.length
        this.$refs["btn-close"].click()
        // console.log(this.loaded)
      }
    },
    // transactionsCat() {
    //   if (this.transactionsCat.donnees) {
    //     this.loaded = true
    //     this.longCat = this.transactionsCat.donnees.length
    //   }
    // },
    successTransaction() {
      if (this.successTransaction) {
        this.$refs["close_modal"].click()
        this.notif.message = "Catégorisation effectuée"
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setSucces("")
          }.bind(this),
          3000
        )
        // location.reload()
      }
    },
    errorsTransaction() {
      if (this.errorsTransaction) {
        this.notif.message = this.errorsTransaction
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setErrors("")
          }.bind(this),
          3000
        )
      }
    },
    comptes() {
      if (this.comptes.donnees) {
        this.comptes.donnees.forEach(compte => {
          this.long += compte.transactions_non_categorisees
        })
      }
    },
    typeTransactions() {
      this.valDecaissements()
    }
  },
  created() {
    // this.loadTransactionsSC(this.transData)
    this.getListComptabiliteTransactions()
    this.loadTypeTransactions()
    this.loadBankAccounts()
    this.leveeFonds()
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.user = userdetails.user_role.split("_")
  },
  computed: {
    ...mapGetters(["transactions", "transactionsCat", "successTransaction", "errorsTransaction", "typeTransactions", "comptes", "decaissements", "leveefond","comptabiliteTransactions"])
  },
  methods: {
    ...mapActions([
      "loadTransactionsSC",
      "loadTransactions",
      "pageComptabiliteTransactions",
      "pageTransactionsCat",
      "updateTransaction",
      "loadTypeTransactions",
      "loadBankAccounts",
      "valDecaissements",
      "leveeFonds",
      "getListComptabiliteTransactions",
    ]),

    ...mapMutations(["setSucces", "setErrors", "setTransactions", "setTransactionsCat", "setLeveeFond","setComptabiliteTransactions"]),
    filtrer() {
      this.loaded = false
      this.longueur = null
      this.setComptabiliteTransactions("")
      this.getListComptabiliteTransactions(this.filtre)
    },
    activate() {},
    // loadGroupedTransactions(status) {
    //   this.groupedTransactions = status
    //   this.stat = status
    //   if (status == true) {
    //     this.loaded = false
    //     this.setTransactions("")
    //     this.loadTransactionsSC(this.transData)
    //   } else {
    //     this.loaded = false
    //     this.setTransactionsCat("")
    //     this.loadTransactions()
    //   }
    // },
    checkCheckbox(event, transaction) {
      this.selectedTransaction = transaction
      this.id = this.selectedTransaction.id
      if (event.target.checked) {
        this.$refs["modal_button"].click()
      }

      this.form = form({
        type: "",
        sous_type: "",
        reference: "",
        commentaires: "",
        champ: "",
        valeur: ""
      })
        .rules({
          type: "required",
          sous_type: "required",
          reference: "required",
          commentaires: "required"
        })
        .messages({
          type: "Ce champs est requis!",
          sous_type: "Ce champs est requis!",
          reference: "Ce champs est requis!",
          commentaires: "Ce champs est requis!"
        })
      this.champList = []
      this.valeurList = []
      this.sousTypeList = []
      this.cat = false
    },
    launchModal(transaction) {
      this.selectedTransaction = transaction
      this.id = this.selectedTransaction.id
      this.selected = this.selectedTransaction
      this.$refs["modal_button"].click()
      this.cat = true

    },
    paging(url) {
      if (this.pIndex == 0) {
        this.pIndex = 1
      }
      this.goodUrl = url.split("/")
      url = this.goodUrl[1]
      var count=1
      var used=0
      url+="&filtres=["  
      this.filtre.forEach(filtre => {
        if (filtre.type == "date") {
          if (filtre.operation!="" && filtre.valeur!="") {
            if (filtre.operation == "interval" && filtre.valeur!="" && filtre.valeur1!="") {
              if (count>1 && used !=0) {
                url+=","
              }
              url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
                +filtre.operation+'","valeur":"'+filtre.valeur+'","valeur1":"'
                +filtre.valeur1+'","periode":"'+filtre.periode+'","type":"'
                +filtre.type+'"}'
              used+=1
            }else{
              if (count>1 && used !=0) {
                url+=","
              }
              url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
                +filtre.operation+'","valeur":"'+filtre.valeur+'","periode":"'+filtre.periode+'","type":"'
                +filtre.type+'"}'
              used+=1
            }
          }
        }else{
          if (filtre.valeur!="" && filtre.nature!="") {
            if (count>1 && used !=0) {
              url+=","
              console.log(count)
            }
            url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","nature":"'+filtre.nature+'","valeur":"'+filtre.valeur+'","type":"'+filtre.type+'"}'
            used+=1
          }
        }
        count+=1
      })
      url+="]"
      this.pageComptabiliteTransactions(url)
    },
    pagingCat(url) {
      if (this.pIndex == 0) {
        this.pIndex = 1
      }
      this.goodUrl = url.split("/")
      url = this.goodUrl[1]
      this.pageTransactionsCat(url)
    },
    // filtrer() {
    //   if (this.stat) {
    //     this.setTransactions("")
    //     this.loadTransactionsSC(this.transData)
    //   } else {
    //     this.setTransactionsCat("")
    //     this.loadTransactions(this.transData)
    //   }
    //   this.transData.libelle = null
    //   this.transData.order = "asc"
    //   this.transData.tri = null
    // },
    getSousType(type) {
      if (type != "") {
        this.sousTypeList = []
        this.typeTransactions.forEach(typeT => {
          if (typeT.type == type) {
            this.sousTypeList = typeT.sous_types
          }
        })
      }
    },
    getChamps(sousType) {
      this.purifier(this.form)
      this.champList = []
      this.valeurList = []
      if (sousType != "") {
        if (sousType == "LEVEE_FONDS") {
          this.champList = this.sousTypeList.champs
        } else if (sousType == "PROGRAMME_ENTRETIEN_ROUTIER") {
          this.champList = []
          this.valeurList = []
          var tmp
          tmp = []
          this.decaissements.donnees.forEach(decaiss => {
            tmp.push(decaiss.id)
          })
          //this.champList = tmp //J'ai essayé de passé une liste mais ça me renvoi une erreur
          this.champList.push({ champ: "decaissement", valeurs: tmp })
        } else {
          this.sousTypeList.forEach(sousT => {
            if (sousT.sous_type == sousType) {
              this.champList = sousT.champs
            }
          })
        }
      }
    },
    getValeurs(champ) {
      this.valeurList = []
      if (champ != "") {
        if (champ == "gare_pesage") {
          this.valeurList = this.champList.valeurs
        } else {
          this.champList.forEach(champT => {
            if (champT.champ == champ) {
              this.valeurList = champT.valeurs
            }
          })
        }
      }

    },
    purifier(form) {
      this.reform = Object.keys(form).reduce((acc, key) => {
        if (key === "commentaires" || key === "reference" || key === "sous_type" || key === "type") {
          acc[key] = form[key]
        }
        return acc
      }, {})
    },
    submit() {
      this.form.validate()
      if (
        !this.form
          .validate()
          .errors()
          .any()
      ) {
        console.log(this.reform)
        this.updateTransaction({ id: this.id, data: this.reform })
      }
    },
    downloadExport(file){
      const link = document.createElement('a')
      link.href = process.env.VUE_APP_UPLOAD+file
      link.download = file
      link.click()
      URL.revokeObjectURL(link.href)
    }
  }
}
</script>

<style>
@import "./operationsBancaires.css";
</style>

<style scoped>
.lt-btn-active {
  width: 188.75px;
  height: 59.01px;
  background: #0097a9;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.005em;
  color: #000000;
}
.lt-btn-inactive {
  width: 188.75px;
  height: 59.01px;
  background: white;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.005em;
  color: rgba(0, 0, 0, 0.25);
}

.btn:focus {
  outline: none !important;
}
</style>
